(function($) {
  $.fn.menumaker = function(options) {  
   var cssmenu = $(this), settings = $.extend({
     format: "dropdown",
     sticky: false
   }, options);
   return this.each(function() {
     $("#menuToggle,.nav-close").on('click', function(){
       $(this).toggleClass('menu-opened');
       $('#headerNav').parent().toggleClass('menuslide-open');
       $('.menu-toggle-overlayer').toggleClass('overlayer-active');
       $('body').toggleClass('slideMenu-active');
       var mainmenu = $("#headerNav").next('ul ul');
       if (mainmenu.hasClass('open')) { 
         mainmenu.slideToggle().removeClass('open');
       }
       else {
         mainmenu.slideToggle().addClass('open');
         if (settings.format === "dropdown") {
           mainmenu.find('ul ul').show();
         }
       }
     });
     cssmenu.find('li ul').parent().addClass('has-sub');
  multiTg = function() {
       cssmenu.find(".has-sub").prepend('<span class="submenu-button"></span>');
       cssmenu.find('.submenu-button').on('click', function() {
         $(this).toggleClass('submenu-opened');
         if ($(this).siblings('ul').hasClass('open')) {
           $(this).siblings('ul').removeClass('open').slideToggle();
         }
         else {
           $(this).siblings('ul').addClass('open').slideToggle();
         }
       });
     };
     if (settings.format === 'multitoggle') multiTg();
     else cssmenu.addClass('dropdown');
     if (settings.sticky === true) cssmenu.css('position', 'fixed');
  resizeFix = function() {
    var mediasize = 1000;
       if ($( window ).width() > mediasize) {
         cssmenu.find('ul ul').show();
       }
       if ($(window).width() <= mediasize) {
         cssmenu.find('ul ul').hide().removeClass('open');
       }
     };
     resizeFix();
     return $(window).on('resize', resizeFix);
   });
    };
  })(jQuery);
  

  


(function(jQuery) {
    $(document).ready(function() {
      $("#headerNav").menumaker({
        format: "multitoggle"
     });
        $('.hero-swiper').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1200,
            autoplay: true,
            autoplaySpeed: 4500,
            pauseOnHover:true,
            fade: true,
            cssEase: 'linear'
          }); 

          $('.quots-swiper').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1200,
            autoplay: true,
            autoplaySpeed: 4500,
            pauseOnHover:true,
            fade: true,
            cssEase: 'linear'
          });       

         

  });
})(jQuery);